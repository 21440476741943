body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Add visible borders to the table */
table {
  border-collapse: collapse;
  width: 80%; /* Adjust the width as needed */
  margin: 20px; /* Adjust the margin as needed */
}

/* Style the header row */
thead th {
  font-weight: bold;
}

/* Add borders to table cells */
table, th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* Style the export buttons */
button {
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 5px; /* Add margin between buttons */
}

/* Adjust button styles on hover */
button:hover {
  background-color: #005a80;
}